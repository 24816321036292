import styled from 'styled-components'

export const StyledIndex = styled.div`
  text-align: center;

  h1 {
    margin-top: 3rem;
  }

  .select-wrap {
    margin-bottom: 0.5rem;
  }

  input {
    margin-bottom: 1rem;
  }
`
