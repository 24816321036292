import React, { useContext } from 'react'
import PhoneInput from 'react-phone-number-input/input'
import { GlobalContext } from '../../utils/GlobalContext'

const InputPhone = ({ id, name }) => {
  const { userTel, setUserTel } = useContext(GlobalContext)

  function handleChange(e) {
    e?.length === 12
      ? (setUserTel(e), console.log(`Phone accepted: ${e}`))
      : setUserTel('')
  }

  return (
    <PhoneInput
      id={id}
      name={name}
      country="US"
      placeholder="(___) ___-____"
      value={userTel}
      onChange={(e) => {
        handleChange(e)
      }}
      minLength="14"
      maxLength="14"
    />
  )
}

export default InputPhone
