import React, { useContext } from 'react'
import { Link } from 'gatsby'
import { GlobalContext } from '../utils/GlobalContext'
import { InputPhone } from '../components/InputPhone'
import { StyledIndex } from '../styles/pages/index.styles'
import { SelectLocation } from '../components/SelectLocation'

const IndexPage = () => {
  const { userTel, userLocation, setUserLocation } = useContext(GlobalContext)

  return (
    <StyledIndex>
      <h1>Mobile Check-in</h1>
      <p>
        Please select the location you are checking in at and enter your phone
        number.
      </p>
      <SelectLocation
        userLocation={userLocation}
        setUserLocation={setUserLocation}
      />
      <form>
        <InputPhone />
      </form>
      <Link
        className="button bigly icon"
        to={`/checkin?location=${userLocation}`}
        disabled={!userTel || !userLocation}
      >
        Next
        <svg
          style={{ marginLeft: `5px` }}
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="m13 7 5 5m0 0-5 5m5-5H6"
          />
        </svg>
      </Link>
    </StyledIndex>
  )
}

export default IndexPage
